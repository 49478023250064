import React from "react"
import { graphql } from "gatsby"
//import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import styles from "./collection.module.scss"

function TemplateCollection({ data }) {

  const dataCollection = data.allWordpressWpCollection.edges[0];
  const collectionTitle = dataCollection.node.title;
  const collectionDescription = dataCollection.node.acf.description;
  const collectionGallery = dataCollection.node.acf.gallery_2 ? dataCollection.node.acf.gallery_2 : [];

  return (
    <Layout>
      <SEO title="Collection" />

      <section className={styles.collection}>
        <div className="flex center" style={{ position: "relative" }}>
          <div className={styles.title}>{collectionTitle}</div>
          <div className={styles.content}>

            {/* Map */}
            {collectionGallery.map(({ gallery_image }, i) =>
              gallery_image ? (
                <div key={i}>
                  <img src={gallery_image.source_url} alt="" />
                </div>
              ) : (
                <div>No images found</div>
              )
            )}

            {/* debug */}
            {/* <pre>{JSON.stringify(dataCollection, null, 4)}</pre> */}

          </div>
          <div className={styles.description}>
            <p dangerouslySetInnerHTML={{ __html: collectionDescription }}></p>
          </div>
        </div>
      </section>

    </Layout>
  )
}
export default TemplateCollection

export const query = graphql`
  query($slug: String!) {
    allWordpressWpCollection(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          title
          slug
          acf {
            description
            gallery_2 {
              gallery_image {
                source_url
              }
            }
          }
        }
      }
    }
  }
`
